<template>
  <v-container class="lighten-5 container">
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto ml-1 mr-1">

          <v-card-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                  mdi-subtitles
                </v-icon>
              </template>
              <span>Título</span>
            </v-tooltip> Título
          </v-card-title>

        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>

          <v-card-text>

            <div class="listyle">
              <div class="fixed-container">
                <v-img class="square img-redondeada" lazy-src="/img/sin_foto.jpg" :src="noticia_foto" />
              </div>
              <div class="my-2 text-subtitle-1">

                <v-text-field v-html="`<h2><strong style='color:#2196f3;'><a style='text-decoration: none;' href='`+noticia_link+`'>`+noticia_medio+`</a></strong></h2><h5> &nbsp; `+ noticia_fecha +`</h5>`" />
                <v-text-field v-html="`<h1>`+noticia_titulo+`</h1>`" />
                <v-text-field v-html="`<p>`+noticia_bajada+`</p>`" />
                <div style="height:10px;"></div>

                <v-btn v-html="`<h5><strong><a style='text-decoration: none; color:white' target='_blank' href='`+noticia_link+`'>Ver noticia completa</a></strong></h5>`" text>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                        mdi-file-eye-outline
                      </v-icon>
                    </template>
                    <span>Ver noticia completa</span>
                  </v-tooltip> 

                </v-btn>
              <div style="height:10px;"></div>
              <div> Compartilo en tus redes.</div>
              <ShareNetwork v-for="network in networks" :network="network.network" :key="network.network" :url="`https://titulos.com.ar/general/`+noticia_medio+`/`+limpiaTexto(noticia_titulo)+`/`+noticia_id" :title="noticia_titulo" :description="sharing.description" :hashtags="sharing.hashtags" :twitterUser="sharing.twitterUser">

                <v-btn text>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                        {{network.icon}}
                      </v-icon>
                    </template>
                    <span>{{ network.name }}</span>
                  </v-tooltip>{{ network.name }}

                </v-btn>

              </ShareNetwork>
              </div>
              

              

            </div>

          </v-card-text>

        </v-card>

      </v-col>

    </v-row>

       <v-row class="mb-6" no-gutters>
      <v-col>
        <v-card id="noticia-completa" class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>

          <v-card-title>Noticia completa de {{noticia_medio}}</v-card-title>
          <v-card-text>

            <div style="height:10px;"></div>

            <iframe :src="noticia_link" class="iframe" dark="false" width="100%" height="800px" sandbox />

          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
  
     <div align="center">
<Adsense text-justify-center
    data-ad-client="ca-pub-1704998024151646"
    data-ad-slot="2204674867">
    </Adsense>
     </div> 




  </v-container>
</template>


<script>
import axios from "axios";

export default {
  name: "TituloA",

  metaInfo() {
    return {
      title: `${this.noticia_titulo}`,
      titleTemplate: '%s - titulos.com.ar',
      meta:[
        { name: 'title', content: `${this.noticia_titulo}` },
        { name: 'description', content: 'Informate rápido y en un solo lugar con las noticias más destacadas de Argentina en titulos.com.ar' },
        { name: 'keywords', content: 'Noticias, Títulos, Titulares, Novedades, Actualidad, Medios, Argentina, Noticias importantes, Noticias recientes, Ucrania, Argentina, Inflación, Clarín, Crónica, La Nación, Infobae, Ámbito, El Cronista, BAE Negocios, El Destape, Página 12, Diario Popular, Minuto Uno y más' },
        { name: 'twitter:site', content: '@ArTitulos' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: `${this.noticia_titulo}` },
        { name: 'twitter:image', content: `${this.noticia_foto}` },
        { name: 'twitter:description', content: 'Titulares más importantes de Argentina' },
        { name: 'twitter:creator', content: '@ArTitulos' },
        { property: 'og:locale', content: 'es-es' },
        { property: 'og:title', content: `${this.noticia_titulo}` },
        { property: 'og:image', content: `${this.noticia_foto}` },
        { property: 'og:url', content: `${this.noticia_link}` },
        { property: 'og:site_name', content: 'Titulos.com.ar' },
        { property: 'og:description', content: 'Titulares más importantes de Argentina' },           
      ],
      link:[
        { rel: 'amphtml', href: 'https://titulos.com.ar/titulo_amp.php?id='+`${this.noticia_id}` }                
      ],
    };
  },

  data: () => ({
    noticia_id: "",
    noticia_titulo: "",
    noticia_link: "",
    noticia_foto: "",
    noticia_bajada: "",
    noticia_medio: "",
    noticia_destacada: "",
    noticia_fecha: "",
    noticia_link_medio: "",

    sharing: {
      description:
        "Este título y otros de mas de 20 medios de comunicación de Argentina en titulos.com.ar",
      hashtags: "titulos.com.ar",
      twitterUser: "ArTitulos",
    },
    networks: [
     
      {
        network: "facebook",
        name: "Facebook",
        icon: "mdi-facebook",
        color: "#1877f2",
      },            
      {
        network: "twitter",
        name: "Twitter",
        icon: "mdi-twitter",
        color: "#1da1f2",
      },
      {
        network: "whatsapp",
        name: "Whatsapp",
        icon: "mdi-whatsapp",
        color: "#25d366",
      },
    ],
  }),

  async mounted() {
    try {
      const res = await axios.post(
        "https://titulos.com.ar/titulo_json.php",
        "id=" + this.$route.params.id
      );
      
      this.noticia_id = res.data.resultados.titulo[0][0]["Id"];
      this.noticia_titulo = res.data.resultados.titulo[0][0]["Titulo"];
      this.noticia_link = res.data.resultados.titulo[0][0]["Href"];
      this.noticia_foto = res.data.resultados.titulo[0][0]["Foto_baja"];
      this.noticia_bajada = res.data.resultados.titulo[0][0]["Bajada"];
      this.noticia_medio = res.data.resultados.titulo[0][0]["Medio"];
      this.noticia_destacada = res.data.resultados.titulo[0][0]["Destacada"];
      this.noticia_fecha = res.data.resultados.titulo[0][0]["Fecha"];

      let tmp_lnk_medio = this.noticia_link.split('/');
      this.noticia_link_medio = tmp_lnk_medio[0]+tmp_lnk_medio[1]+tmp_lnk_medio[2];
    } catch (e) {
      console.error(e);
    }

   
  },
  methods: {
    onLoad(frame) {
      this.myIframe = frame.contentWindow;
    },

    limpiaTexto(titulo) {
      titulo = titulo.toLowerCase();
      titulo = titulo.replace(/[^\w\d\s]/g, "");
      titulo = titulo.replace(/[\s]/g, "-");
      titulo = titulo.substring(0, 100);
      return titulo;
    },
  },
};
</script>

<style scoped>
.v-application .text-subtitle-1 {
  line-height: 2rem !important;
}

.v-application a {
  text-decoration: none !important;
}

.v-card__subtitle,
.v-card__text {
  padding: 25px !important;
}

.v-card__title {
  padding: 15px !important;
}
.iframe {
  background: #ffff;
}
.foto-despliegue {
  width: "100%";
}
.img-redondeada {
  width: 350px;
}

@media (min-width: 300px) and (max-width: 700px) {
  .fixed-container {
    float: none;
  }
  .img-redondeada {
    width: 100%;
  }
}
</style>