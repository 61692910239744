var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5 container"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"mx-auto ml-1 mr-1"},[_c('v-card-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"secundary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-subtitles ")])]}}])},[_c('span',[_vm._v("Título")])]),_vm._v(" Título ")],1)],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"mx-auto my-2 ml-1 mr-1"},[_c('div',{staticStyle:{"height":"10px"}}),_c('v-card-text',[_c('div',{staticClass:"listyle"},[_c('div',{staticClass:"fixed-container"},[_c('v-img',{staticClass:"square img-redondeada",attrs:{"lazy-src":"/img/sin_foto.jpg","src":_vm.noticia_foto}})],1),_c('div',{staticClass:"my-2 text-subtitle-1"},[_c('v-text-field',{domProps:{"innerHTML":_vm._s("<h2><strong style='color:#2196f3;'><a style='text-decoration: none;' href='"+_vm.noticia_link+"'>"+_vm.noticia_medio+"</a></strong></h2><h5> &nbsp; "+ _vm.noticia_fecha +"</h5>")}}),_c('v-text-field',{domProps:{"innerHTML":_vm._s("<h1>"+_vm.noticia_titulo+"</h1>")}}),_c('v-text-field',{domProps:{"innerHTML":_vm._s("<p>"+_vm.noticia_bajada+"</p>")}}),_c('div',{staticStyle:{"height":"10px"}}),_c('v-btn',{attrs:{"text":""},domProps:{"innerHTML":_vm._s("<h5><strong><a style='text-decoration: none; color:white' target='_blank' href='"+_vm.noticia_link+"'>Ver noticia completa</a></strong></h5>")}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"secundary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-eye-outline ")])]}}])},[_c('span',[_vm._v("Ver noticia completa")])])],1),_c('div',{staticStyle:{"height":"10px"}}),_c('div',[_vm._v(" Compartilo en tus redes.")]),_vm._l((_vm.networks),function(network){return _c('ShareNetwork',{key:network.network,attrs:{"network":network.network,"url":"https://titulos.com.ar/general/"+_vm.noticia_medio+"/"+_vm.limpiaTexto(_vm.noticia_titulo)+"/"+_vm.noticia_id,"title":_vm.noticia_titulo,"description":_vm.sharing.description,"hashtags":_vm.sharing.hashtags,"twitterUser":_vm.sharing.twitterUser}},[_c('v-btn',{attrs:{"text":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"secundary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(network.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(network.name))])]),_vm._v(_vm._s(network.name)+" ")],1)],1)})],2)])])],1)],1)],1),_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"mx-auto my-2 ml-1 mr-1",attrs:{"id":"noticia-completa"}},[_c('div',{staticStyle:{"height":"10px"}}),_c('v-card-title',[_vm._v("Noticia completa de "+_vm._s(_vm.noticia_medio))]),_c('v-card-text',[_c('div',{staticStyle:{"height":"10px"}}),_c('iframe',{staticClass:"iframe",attrs:{"src":_vm.noticia_link,"dark":"false","width":"100%","height":"800px","sandbox":""}})])],1)],1)],1),_c('div',{attrs:{"align":"center"}},[_c('Adsense',{attrs:{"text-justify-center":"","data-ad-client":"ca-pub-1704998024151646","data-ad-slot":"2204674867"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }