<template>
  <div>    
    <Despliegue />
    <Palabras />
  </div>
</template>

<script>
import Palabras from "@/components/palabras.vue";
import Despliegue from "@/components/despliegue.vue";



export default {
  inheritAttrs: false,


  name: "Titulo",
  props: ["id"],
  components: {
    Palabras,
    Despliegue,


 
  },
  data() {
    return {
      show: true,
      componentKey: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.componentKey += 1;
      }
    },
  },
};
</script>