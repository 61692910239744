<template>
  <v-container class="lighten-5 container">
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto ml-1 mr-1">

          <v-card-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                  mdi-fire
                </v-icon>
              </template>
              <span>Palabras clave más mencionadas en los distintos títulos del las últimas horas</span>
            </v-tooltip> Lo que está pasando
          </v-card-title>

          <v-divider class="mx-4"></v-divider>

          <v-card-text class="text-center">
            <div class="text-center">

              <v-chip class="ma-2" v-for="item in palabras" :key="item.id" color="red" text-color="white" @click="Palabra(item)">{{ item.toUpperCase() }}</v-chip>

            </div>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
     
    
    
  </v-container>
</template>

<script>

import axios from "axios";
export default {
  name: "Palabras",
  
  data() {
    return {
      palabras: [],
      loading: true,
      firstLoad: true,
      //id: this.$route.params.id
      attrs: {
        class: "mb-12",
        dark: true,
      },
    };
  },

  async mounted() {
    try {
      const res = await axios.post("https://titulos.com.ar/palabras.json");

      this.palabras = res.data.resultados.plabras[0];

      for (var i = 0; i < this.palabras.length; i++) {
        if (this.palabras[i] === "") {
          this.palabras.splice(i, 1);
          i--;
        }
      }
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    Palabra(texto) {
      this.$router.push("/buscar/" + texto);
    },
  },
};
</script>
<style scoped>
.v-card__title {
  padding: 15px !important;
}
</style>
